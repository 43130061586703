import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import imagesLoaded from 'flickity-imagesloaded';
import magnificPopup from 'magnific-popup';

export default function initSliders() {

    // Translation of Magnific Popup gallery
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'X (Esc)',
        tLoading: '...',
        gallery: {
            tPrev: '&#8592;',
            tNext: '&#8594;',
            tCounter: '%curr% / %total%'
        },
        image: {
            tError: 'x-x'
        },
        ajax: {
            tError: 'x-x'
        }
    });

    // make Flickity a jQuery plugin
    Flickity.setJQuery($);
    jQueryBridget( 'flickity', Flickity, $ );
    // now you can use $().flickity()

    var sliderGallery = $(".sliderel").flickity({
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        adaptiveHeight: false,
        pageDots: true,
        wrapAround: true
    });

    var sliderGalleryItems = $.map($(".sliderel").find("img"), function(el) {
        return { src: el.dataset.full };


    });

    var listToRepeat = "<li></li>";
    var activeIndex = 0;
    sliderGallery.on("staticClick.flickity", function(event, pointer, cellElement, cellIndex) {
        if (!cellElement) {
            return;
        }

        $.magnificPopup.open(
            {
                items: sliderGalleryItems,
                type: "image",
                gallery: {
                    enabled: true,
                    preload: [0]
                },
                image: {
                    markup:
                        '<div class="mfp-custom-header">' +
                        '<div class="mfp-close"></div>' +
                        '<div class="mfp-close-text">Schließen</div>' +
                        '<div class="mfp-img"></div>' +
                        '</div>' +
                        '<div class="mfp-bottom-bar">' +
                        '<div class="mfp-title"></div>' +
                        '<ul class="mfp-pagination">' + listToRepeat.repeat(sliderGalleryItems.length) + '</ul>' +
                        '</div>'
                },
                callbacks: {
                    open: function() {
                        activeIndex = this.index + 1;
                        $('.mfp-pagination li').removeClass('is-selected');
                        $('.mfp-pagination li:nth-child(' + activeIndex + ')').addClass('is-selected');
                    },
                    change: function() {
                        activeIndex = this.index;
                        var paginationItems = this.content[1].querySelectorAll('.mfp-pagination li');
                        $(paginationItems).removeClass('is-selected');
                        $(paginationItems[activeIndex]).addClass('is-selected');
                    },
                }
            }, cellIndex );
    });

}