// import Cookies from 'js-cookie';
// import {addClass, toggleClass} from "../components-nfw/helpers";
import {getCookie, removeCookie, setCookie} from "./cookiehandler";
import {cookieNoticeSettings, cookieSets, cookieSettings} from "./config";
import {cnEls, updateOptions} from "./cookienotice";
import {cookieState} from "./index";
import {hasClass, lang} from "./helpers";


export function setVBCNCookie(cookieValue) {
    let cookieValueStr = null;
    if(typeof cookieValue === "object") {
        cookieValueStr = JSON.stringify(cookieValue);
    } else {
        cookieValueStr = cookieValue
    }
    // setCookie(name, value[, end[, path[, domain[, secure]]]])

    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + cookieSettings.cookieLifeTime);

    setCookie(cookieSettings.cookiename, cookieValueStr, endDate, '/', window.location.host, true);
    cookieState.cookiesAllowed = true;
    cookieState.allowedData = cookieValue;

    // if(!window.cookieState) {
    window.cookieState =  cookieState;
    // }

    updateOptions(cookieValue.options);

}

export function unsetVBCNCookie() {
    removeCookie(cookieSettings.cookiename, '/', window.location.host);

    if(cookieState) {
        cookieState.cookiesAllowed = false;

        let allowedData = cookieState.allowedData;

        if(allowedData) {
            allowedData.cookiesAllowed = false;

            let options = cookieState.allowedData.options;

            for (let option in options) {
                if(options.hasOwnProperty(option)) {
                    options[option] = false;
                }
            }

            cookieState.allowedData.options = options;

            updateOptions(options)
        }
    }
}

export function renewVBCNCookie() {
    let currentData = getVBCNCookieData();
    setVBCNCookie(currentData);
}

export function enableSpecificType(type) {
    console.log(type);
    let currentData =  getVBCNCookieData();

    if(!currentData) {
        currentData = collectVBCNCookieData();
        if (!currentData.cookiesAllowed) {
            currentData = updateCookieData(currentData);
        }
    }

    if(currentData.options.hasOwnProperty(type)) {
        currentData.options[type] = true;
    } else {
        currentData.options[type] = true;
    }
    unsetVBCNCookie();
    setVBCNCookie(currentData);
}

export function updateCookieData(cookieData) {
    if(cookieNoticeSettings.autoEnableMandatoryOnPlaceholder) {
        cookieData['cookiesAllowed'] = true;
        let mandatoryOptions = cookieSets[lang].filter(cookieSet => cookieSet.mandatory);

        mandatoryOptions.forEach(option => {
            cookieData.options[option.id] = true;
        })
    }

    return cookieData;
}

export function updateVBCNCookieData(key, data) {
    let currentData =  getVBCNCookieData();

    if(!currentData) {
        currentData = collectVBCNCookieData();
    }

    if(currentData.hasOwnProperty(key)) {
        currentData[key] = data;
    } else {
        currentData[key] = null;
        currentData[key] = data;
    }

    unsetVBCNCookie();
    setVBCNCookie(currentData);
}


export function getVBCNCookieData() {
    let cookieValue = getCookie(cookieSettings.cookiename);

    try {
        cookieValue = JSON.parse(cookieValue);
    } catch (e) {
        //cookieValue is not Json
        // cookieValue = {};
    }

    return cookieValue;
}


export function collectVBCNCookieData() {
    let cookieValue = {
        cookiesAllowed: null,
        options: {}
    };

    let mandatoryOptions = cookieSets[lang].filter(cookieSet => cookieSet.mandatory);

    cnEls.cnOptions.forEach(option => {
        let input = option.querySelector('.vbcn-option-input');
        let optionID = input.dataset.option;
        let allowedState = input.checked;
        cookieValue.options[optionID] = allowedState;
    });

    let uncheckedMandatory = mandatoryOptions.filter(option => {
        return cookieValue.options[option.id] !== true;
    });

    console.log(uncheckedMandatory);

    cookieValue.cookiesAllowed = uncheckedMandatory.length <= 0;

    return cookieValue;
}
