export const cookieSettings = {
    cookiename: "cookiesettings",
    cookieLifeTime: 1, // in months
};

export const cookieNoticeSettings = {
    centerMode: false,
    ignoredPages: [
        '/datenschutz',
    ],
    hasDeclineButton: true,
    backgroundColor: '#493d41',
    textColor: 'white',
    useBackdrop: false,
    backdropColor: '#333',
    backdropOpacity: '0.8',
    autoEnableMandatory: true,
    autoEnableMandatoryOnPlaceholder: true,
};

export const cookieNoticeTexts = {
    "de": {
        initialHeadline: 'Cookies, externe Dienste & Datenschutz',
        customizeHeadline: 'Ihre Datenschutz-Einstellungen',
        explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. \n\n " +
            "Zur Analyse der Zugriffe auf unsere Website verwenden wir Matomo Analytics. \n\n" +
            "Außerdem binden wir Skripte von YouTube ein. \n\n" +
            "Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
        accept_preset_ButtonText: 'Alle akzeptieren',
        declineButtonText: 'Ablehnen ',
        accept_all_ButtonText: 'Alle Cookies akzeptieren',
        decline_all_ButtonText: 'Alle ablehnen',
        customizeButtonText: 'Einstellungen',
        cancel_customizeButtonText: 'Zurück',
        saveButtonText: 'Speichern',
        closeButtonText: '×'
    },
    "en": {
        initialHeadline: 'Accept cookies & Privacy Policy?',
        customizeHeadline: 'Select cookies to accept',
        explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
            "We use [[ Google Analytics / Matomo Analytics ]] to analyze the usage  of our website. \n\n" +
            "We embed scripts from these external service providers: YouTube \n\n" +
            "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
        accept_preset_ButtonText: 'Accept all',
        declineButtonText: 'Decline',
        accept_all_ButtonText: 'Accept all',
        decline_all_ButtonText: 'Decline all',
        customizeButtonText: 'Customize',
        cancel_customizeButtonText: 'Go back',
        saveButtonText: 'Save',
        closeButtonText: '×'
    }
};

export const cookieSets = {
    "de": [
        {
            id: 'mandatory',
            name: 'Notwendige Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
            mandatory: true,
            mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.'
        },/* {
            id: 'google_analytics',
            name: 'Google Analytics',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        }, */{
            id: 'matomo_analytics',
            name: 'Matomo Analytics',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        },/* {
            id: 'google_maps',
            name: 'Google Maps',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an Google übertragen werden.'
        }, {
            id: 'mapbox_maps',
            name: 'Mapbox',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an Mapbox übertragen werden.'
        }, */{
            id: 'youtube_video',
            name: 'YouTube Video',
            description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an YouTube übertragen werden.'
        }/*, {
            id: 'vimeo_video',
            name: 'Vimeo Videos',
            description: 'Diese Skripte und Cookies sind nötig, um Vimeo Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an Vimeo übertragen werden.'
        }, {
            id: 'facebook',
            name: 'Facebook',
            description: 'Diese Skripte und Cookies sind nötig, um unseren Facebook-Stream auf der Seite einzubinden. Durch das Aktivieren werden Skripte von Facebook eingebunden. Dabei können personenbezogene Daten an Facebook übertragen werden.'
        }, {
            id: 'twitter',
            name: 'Twitter',
            description: 'Diese Skripte und Cookies sind nötig, um unseren Twitter-Stream auf der Seite einzubinden. Durch das Aktivieren werden Skripte von Twitter eingebunden. Dabei können personenbezogene Daten an Twitter übertragen werden.'
        }, {
            id: 'social_streams',
            name: 'Social Media Streams',
            description: 'Diese Skripte und Cookies sind nötig, um unsere Social Media Streams auf der Seite einzubinden. Durch das Aktivieren können personenbezogene Daten an Facebook, Twitter, Instagram und YouTube übertragen werden.'
        }, {
            id: 'podigee_podcast',
            name: 'Podigee Podcast-Hosting',
            description: 'Wir nutzen den Podcast-Hosting-Dienst Podigee des Anbieters Podigee. Dabei werden IP-Adressen und Geräteinformationen verarbeitet um Podcast-Downloads/Wiedergaben zu ermöglichen und Abrufzahlen zu ermitteln.'
        }, {
            id: 'sendinblue',
            name: 'sendinblue Newsletter registration',
            description: 'lorem ipsum .'
        }*/
        ],
    "en": [
        {
            id: 'mandatory',
            name: 'Essential cookies',
            description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
            mandatory: true,
            mandatory_text: "These cookies are mandatory."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Cookies that ensure the design of our webpages. No personal data are collected.'
        }, /*{
            id: 'google_analytics',
            name: 'Google Analytics',
            description: 'Scripts and cookies that gather information about visitors of the site, for example country of origin, device settings and browsers.'
        }, */{
            id: 'matomo_analytics',
            name: 'Matomo Analytics',
            description: 'Scripts and cookies that gather information about visitors of the site, for example country of origin, device settings and browsers.'
        },/* {
            id: 'google_maps',
            name: 'Google Maps',
            description: 'Scripts and cookies that are required to display the maps of this service. On activation personal data might be forwarded to Google.'
        }, {
            id: 'mapbox_maps',
            name: 'Mapbox',
            description: 'Scripts and cookies that are required to display the maps of this service. On activation personal data might be forwarded to Mapbox.'
        },*/ {
            id: 'youtube_video',
            name: 'YouTube Video',
            description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to YouTube (Google).'
        }/*, {
            id: 'vimeo_video',
            name: 'Vimeo Videos',
            description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to Vimeo.'
        }, {
            id: 'facebook',
            name: 'Facebook Cookies',
            description: 'Scripts and cookies that are required to integrate our Facebook stream on this website. On activation personal data might be forwarded to Facebook.'
        }, {
            id: 'twitter',
            name: 'Twitter Cookies',
            description: 'Scripts and cookies that are required to integrate our Twitter stream on this website. On activation personal data might be forwarded to Twitter.'
        }, {
            id: 'social_streams',
            name: 'Social Media Streams',
            description: 'Scripts and cookies that are required to integrate our Social Media  streams on this website. On activation personal data might be forwarded to Facebook, Twitter, Instagram and YouTube (Google).'
        }, {
            id: 'podigee_podcast',
            name: 'Podigee Podcast-Hosting',
            description: 'We use Podigee service to integrate podcasts on our website. On activation IP addresses and information about the device in use are processed to enable download/playback podcasts and to get the number of downloads/calls/hits.'
        }, {
            id: 'sendinblue',
            name: 'sendinblue Newsletter registration',
            description: 'lorem ipsum .'
        }*/]
};
