"use strict";

export const scriptsStack = {
    mandatory: []
};

export function collectScripts() {
    let allScriptTags = document.querySelectorAll('script[type="x-script/vbcn"]');

    Array.from(allScriptTags).forEach(scriptTag => {
        var scriptType = scriptTag.dataset.type;
        var subType = scriptTag.src !== "" ? 'src' : 'inline';

        if (scriptsStack.hasOwnProperty(scriptType)) {
            scriptsStack[scriptType].push({type: subType, tag: scriptTag});
        } else {
            scriptsStack[scriptType] = [];
            scriptsStack[scriptType].push({type: subType, tag: scriptTag});
        }
    });

    let allIframes = document.querySelectorAll('.js-vbcn-iframe[data-src]');

    Array.from(allIframes).forEach(iframe => {
        var type = iframe.dataset.type;
        var subType = 'iframe';

        if (scriptsStack.hasOwnProperty(type)) {
            scriptsStack[type].push({type: subType, tag: iframe});
        } else {
            scriptsStack[type] = [];
            scriptsStack[type].push({type: subType, tag: iframe});
        }
    });

    let allTemplates = document.querySelectorAll('.js-vbcn-template');

    Array.from(allTemplates).forEach(template => {
        var type = template.dataset.type;
        var subType = 'template';

        if (scriptsStack.hasOwnProperty(type)) {
            scriptsStack[type].push({type: subType, tag: template});
        } else {
            scriptsStack[type] = [];
            scriptsStack[type].push({type: subType, tag: template});
        }
    });
    // console.log(scriptsStack);

    let allStyleNodes = document.querySelectorAll('style[type="x-style/vbcn"]');

    Array.from(allStyleNodes).forEach(styleNode => {
        var type = styleNode.dataset.type;
        var subType = 'inline-style';

        if (scriptsStack.hasOwnProperty(type)) {
            scriptsStack[type].push({type: subType, tag: styleNode});
        } else {
            scriptsStack[type] = [];
            scriptsStack[type].push({type: subType, tag: styleNode});
        }
    });


    let allStyleLinks = document.querySelectorAll('link[type="x-style/vbcn"]');

    Array.from(allStyleLinks).forEach(styleLink => {
        var type = styleLink.dataset.type;
        var subType = 'style-link';

        if (scriptsStack.hasOwnProperty(type)) {
            scriptsStack[type].push({type: subType, tag: styleLink});
        } else {
            scriptsStack[type] = [];
            scriptsStack[type].push({type: subType, tag: styleLink});
        }
    });
}

export function evalScript(elem) {
    var data = (elem.text || elem.textContent || elem.innerHTML || ""),
        parent = elem.parentNode,
        script = document.createElement("script");

    script.type = "text/javascript";
    try {
        // doesn't work on ie...
        script.appendChild(document.createTextNode(data));
    } catch (e) {
        // IE has funky script nodes
        script.text = data;
    }

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "src") {
            script.setAttribute(attribute.name, attribute.value)
        }
    });

    if (script.parentNode) {
        script.parentNode.removeChild(script);
    }

    if(parent) {
        parent.insertBefore(script, elem);
        parent.removeChild(elem);
    }
}

export function loadScript(elem) {
    var src = (elem.src || ""),
        parent = elem.parentNode,
        script = document.createElement("script");

    script.type = "text/javascript";
    script.src = src;

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "src") {
            script.setAttribute(attribute.name, attribute.value)
        }
    });

    if (script.parentNode) {
        script.parentNode.removeChild(script);
    }

    if(parent) {
        parent.insertBefore(script, elem);
        parent.removeChild(elem);
    }
}

export function loadIframe(elem) {
    if(elem.dataset.src) {
        elem.src = elem.dataset.src;
        elem.removeAttribute('data-src');
    }
}

export function evalStyle(elem) {
    let data = (elem.text || elem.textContent || elem.innerHTML || "");
    let parent = styleNode.parentNode;
    let style = document.createElement('style')

    style.innerHTML = data;

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "href" && attribute.name !== "data-href" && attribute.name !== "data-type") {
            style.setAttribute(attribute.name, attribute.value)
        }
    });

    if (style.parentNode) {
        style.parentNode.removeChild(style);
    }

    if(parent) {
        parent.insertBefore(style, elem);
        parent.removeChild(elem);
    }
}

export function loadStyle(elem) {
    var href = (elem.dataset.href || ""),
        parent = elem.parentNode,
        link = document.createElement("link");

    link.rel = "stylesheet";
    link.href = href;

    // script.type = "text/javascript";
    // script.src = src;

    Array.from(elem.attributes).forEach(attribute => {
        if(attribute.name !== "type" && attribute.name !== "href" && attribute.name !== "data-href" && attribute.name !== "data-type") {
            link.setAttribute(attribute.name, attribute.value)
        }
    });

    if (link.parentNode) {
        link.parentNode.removeChild(link);
    }

    if(parent) {
        parent.insertBefore(link, elem);
        parent.removeChild(elem);
    }
}

// function Sleep(milliseconds) {
//     return new Promise(resolve => setTimeout(resolve, milliseconds));
// }
//
// function waitForLinks(links) {
//     let count = links.length;
//
//     console.log(links);
//     debugger;
//     return new Promise(resolve => {
//         links.forEach(link => {
//             link.addEventListener('load', e => {
//                 debugger;
//                 console.log(count);
//                 // console.log(link + " loaded");
//                 count--
//                 console.log(count);
//                 console.log(resolve);
//                 if(count <= 0) {
//                     console.log(resolve);
//                     resolve("done");
//                 }
//             })
//         })
//     })
// }

export async function handleTemplate(elem) {
    var parent = elem.parentNode,
        newElement = document.createElement("div");

    newElement.style.display = "none";

    let clone = elem.content.cloneNode(true);

    // Insert styles before loading the element
    let allStyleNodes = clone.querySelectorAll('style[type="x-style/vbcn"]');

    if(allStyleNodes.length > 0) {
        Array.from(allStyleNodes).forEach(styleNode => {
            let nodeParent = styleNode.parentNode;
            let node = document.createElement('style')
            node.innerHTML = styleNode.innerHTML;

            if(nodeParent) {
                nodeParent.insertBefore(node, styleNode);
                nodeParent.removeChild(styleNode);
            }
        });
    }

    let allStyleLinks = clone.querySelectorAll('link[type="x-style/vbcn"]');

    let linkCount = allStyleLinks.length;

    if(linkCount > 0) {
        Array.from(allStyleLinks).forEach(styleLink => {
            let nodeParent = styleLink.parentNode;

            let link = document.createElement('link')
            link.rel = "stylesheet";
            link.href = styleLink.dataset.href;

            link.addEventListener('load', e => {
                linkCount--
            })

            if(nodeParent) {
                nodeParent.insertBefore(link, styleLink);
                nodeParent.removeChild(styleLink);
            }
        });
    }


    newElement.appendChild(clone);

    if (newElement.parentNode) {
        newElement.parentNode.removeChild(newElement);
    }

    if(parent) {
        parent.insertBefore(newElement, elem);
        parent.removeChild(elem);
    }

    let allScriptTags = newElement.querySelectorAll('script[type="x-script/vbcn"]');

    Array.from(allScriptTags).forEach(scriptTag => {
        let subType = scriptTag.src !== "" ? 'src' : 'inline';

        if(subType === "inline") {
            evalScript(scriptTag);
        }
        if(subType === "src") {
            loadScript(scriptTag);
        }
    });

    if(linkCount <= 0) {
        newElement.style.display = "block";
    } else {
        setTimeout(() => {
            // console.log(linkCount);
            newElement.style.display = "block";
        }, 500);
    }
}


export function executeScripts(scriptType) {
    if (!scriptType) {
        return false;
    }
    if (scriptType === "all" ) {
        for (var type in scriptsStack) {
            if (scriptsStack.hasOwnProperty(type)) {
                scriptsStack[type].forEach((script, i) => {
                    if(script.type === "inline") {
                        evalScript(script.tag);
                    }
                    if(script.type === "src") {
                        loadScript(script.tag);
                    }
                    if(script.type === "iframe") {
                        loadIframe(script.tag);
                    }
                    if(script.type === "template") {
                        handleTemplate(script.tag);
                    }
                    if(script.type === "inline-style") {
                        evalStyle(script.tag);
                    }
                    if(script.type === "style-link") {
                        loadStyle(script.tag);
                    }
                });
            }
        }
    } else {
        if(scriptsStack.hasOwnProperty(scriptType)) {
            scriptsStack[scriptType].forEach((script, i) => {
                if(script.type === "inline") {
                    evalScript(script.tag);
                }
                if(script.type === "src") {
                    loadScript(script.tag);
                }
                if(script.type === "iframe") {
                    loadIframe(script.tag);
                }
                if(script.type === "template") {
                    handleTemplate(script.tag);
                }
                if(script.type === "inline-style") {
                    evalStyle(script.tag);
                }
                if(script.type === "style-link") {
                    loadStyle(script.tag);
                }
            });
        }
    }
}
