import $ from "jquery";

export function headerTriggers() {
    function openDrawer(a, b, c, d) {
        a.classList.add('is-opened');
        b.classList.remove('is-closed');
        c.style.display = 'block';
        d.classList.add('is-visible');
    }

    function closeDrawer(a, b, c, d) {
        a.classList.remove('is-opened');
        b.classList.add('is-closed');
        c.style.display = 'none';
        d.classList.remove('is-visible');
    }

    function listenerDrawer(a, b, c, d) {
        a.addEventListener('click', d, false);
        b.addEventListener('click', d, false);
        c.addEventListener('click', d, false);
    }

    var menuOpener = document.querySelector('.trigger-menu-open');
    if(menuOpener) {
        var menuCloser = document.querySelector('.trigger-menu-close');
        var menuOverlay = document.querySelector('.menu-overlay');
        var menu = document.querySelector('.menu');

        function menuLogic() {
            if (menuOpener.classList.contains('is-opened')) {
                closeDrawer(menuOpener, menuCloser, menuOverlay, menu);
            } else {
                openDrawer(menuOpener, menuCloser, menuOverlay, menu);
            }

            // Close menu if linkis on same page
            $('.menu-link').on('click', function() {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    closeDrawer(menuOpener, menuCloser, menuOverlay, menu);
                }
            })
        }

        listenerDrawer(menuOpener, menuCloser, menuOverlay, menuLogic);
    }

    var contactOpener = document.querySelector('.trigger-kontakt-open');
    if(contactOpener) {
        var contactCloser = document.querySelector('.trigger-kontakt-close');
        var contactOverlay = document.querySelector('.contact-overlay');
        var contact = document.querySelector('.menu-kontakt');

        function contactLogic() {
            if (contact.classList.contains('is-visible')) {
                closeDrawer(contactOpener, contactCloser, contactOverlay, contact);
                menu.classList.remove('is-bigger');
            } else {
                openDrawer(contactOpener, contactCloser, contactOverlay, contact);
                menu.classList.add('is-bigger');
            }
        }

        listenerDrawer(contactOpener, contactCloser, contactOverlay, contactLogic);

    }

}

// More section on homepage
export function homeMoreSection() {

    $('.more-open').on('click', function() {
        $(this).hide();
        $('.more-close').show();
        $('.more-content').slideDown(200);
    });

    $('.more-close').on('click', function() {
        $(this).hide();
        $('.more-open').show();
        $('.more-content').slideUp(200);
    });

}

// Enable tabs via JS
export function tabTriggers() {
    $('.tabs-item').on('click', function() {
        if(!$(this).hasClass('is-active') && $(this).data('tab') !== undefined) {
            // Animate waves
            $('.tabs-waves svg').css('transform', 'translateX(-' + ($('.tabs-item').index(this) + 1) * 6.5 + '%)');

            $('.tabs-item').removeClass('is-active');
            $('.tab-content').slideUp(200);
            $(this).addClass('is-active');

            var tabName = $(this).data('tab');
            $('.tab-content[data-tab="' + tabName + '"]').slideDown(200);

            $('html, body').animate({
                scrollTop: $(this).offset().top
            }, 400);

        }
    })
}

// Partners
export function partnersTriggers() {

    $('.partners-more').on('click', function() {
        $(this).hide();
        var parent = $(this).parent().parent();
        parent.find('.partners-close').show();
        parent.find('.partners-links-original').hide();
        parent.find('.partners-links-additional').show();
        parent.find('.partners-fulltext').slideDown(200);
    });

    $('.partners-close').on('click', function() {
        $(this).hide();
        var parent = $(this).parent().parent();
        parent.find('.partners-more').show();
        parent.find('.partners-links-original').show();
        parent.find('.partners-links-additional').hide();
        parent.find('.partners-fulltext').slideUp(200);
    });

}

// Filters
export function filtersTriggers() {

    var filterTrigger = document.getElementById('filter-trigger');
    if(filterTrigger) {
        filterTrigger.addEventListener('click', function() {
            if(this.parentNode.classList.contains('is-triggered')) {
                this.parentNode.classList.remove('is-triggered');
            } else {
                this.parentNode.classList.add('is-triggered');
            }
        })
    }

}
