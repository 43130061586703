import $ from 'jquery';
import lazysizes from 'lazysizes';
const jQuery = require('jquery');
import Parlx from 'parlx.js';
import magnificPopup from 'magnific-popup';

// Parallax
export function topParallax() {

   const elems = document.querySelectorAll('.parallax');

   const parlx = Parlx.init({
       elements: elems,
       settings: {
           speed: 0.2,
           type: 'foreground',
           height: 'auto'
       }
   });
}

// Search bpx animation functionality
export function newsLetterAnimation() {
    var newsLetterField = [].slice.call(document.getElementsByClassName('newsletter-input'));
    for (var i = 0; i < newsLetterField.length; i++) {
        newsLetterField[i].addEventListener('keyup', function () {
            var length = this.value.length;
            if (length > 0) {
                var newsLetterCheck = this.nextElementSibling;
                var newsLetterSubmit = this.nextElementSibling.nextElementSibling;
                newsLetterCheck.style.display = 'block';
                newsLetterSubmit.style.marginTop = '20px';
                setTimeout(function () {
                    newsLetterCheck.style.visibility = 'visible';
                    newsLetterCheck.style.opacity = '1';
                }, 10);
            }
        });
    }
}

// Lazy Loading
export function lazyLoading() {
    document.addEventListener('lazybeforeunveil', function(e){
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });
}

// Smooth Scrolling
export function smoothScrolling() {
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function() {
                    var $target = $(target);
                });
            }
        }
    });
}

export function lightBox() {
    if($('.lightbox').length) {
        $('.lightbox').magnificPopup({
            type: 'image',
            image: {
                titleSrc: function(item) {
                    if (item.el.find('figcaption').html() === undefined) {
                        return '';
                    } else {
                        console.log('aaaaaaaaabbbb');
                        return item.el.find('figcaption').html();
                    }
                }
            }
        });
    }
}
